/* global AppSettings */
/**
 * The module handles the recently viewed items on the Lister + Detail pages.
 * It listens to add and render events and stores data into LocalStorage.
 * A client side template is used to render the items on page. The images
 * are loaded lazy via the ImageLazyLoad module.
 */
import $ from 'jquery';
import { gsap, CSSPlugin } from 'gsap/all';
import onWindowLoaded from 'components/utils/onWindowLoaded';
import MustacheWrapper from 'components/utils/MustacheWrapper';
import createLogger from '../logger/Logger';
import EventTypes from '../EventTypes';
import StorageKeys from '../utils/storage/StorageKeys';
import Storage from '../utils/storage/Storage';
import RvpManager from './RecentlyViewedProductsManager';
import TileDataProcessing from '../utils/tileDataProcessing';
import scrollTo from '../utils/scrollTo';
import ImageLazyLoad from '../utils/ImageLazyLoad';
import TrackingObserver from '../productLister--v2/TrackingObserver';
import device from '../utils/device';
import productTemplate from './templates/recentlyViewedProductListerTeaser.html';
import matchBreakpoint from '../utils/matchBreakpoint';
import SlideCarousel from '../carousels/SlideCarousels';
import { getCSRFToken } from '../utils/CsrfUtils';
import FiberRanking from '../fiberRanking/FiberRanking';

const Logger = createLogger('RecentlyViewedProducts');
gsap.registerPlugin(CSSPlugin);

const SKU_LIST_URL = `/${AppSettings.locale}/recentlyViewedProducts/filter/`;
const SERVER_SYNC_EXPIRY = Date.now() - 24 * 60 * 60 * 1000; // 1 day

const Selectors = {
  TEMPLATE: '#rvpProductTile-template',
  CONTAINER: '.js-recentlyViewedProducts',
  CAROUSEL: '.js-slideCarousel--recentlyViewedProducts',
  CAROUSEL_CONTENTS: '.js-recentlyViewedProducts-inner',
  PRODUCT: '.js-productListerTeaser, .js-productTile',
  LAZY_IMAGE: 'img.is-lazy',
  REMOVE: '.js-removeFromStore',
  CLOSE_COMPONENT: '.js-closeSliderCarousel',
};

const Classes = {
  HIDDEN: 'is-hidden',
  CAROUSEL_INITIALIZED: 'js-slideCarousel-initialized',
};

const v2Template = document.querySelector(Selectors.TEMPLATE);
const isV2 = Boolean(v2Template);
const template = isV2 ? v2Template.textContent : productTemplate;
const rvpManager = RvpManager.getInstance();

/**
 * Recently Viewed Products carousel class.
 */
class RecentlyViewedProducts {
  /**
   * Constructs the object.
   */
  constructor() {
    const carouselComponent = document.querySelector(Selectors.CAROUSEL);
    this.initialRenderPerformed = false;
    this.container = document.querySelector(Selectors.CONTAINER);
    this.oneRowOnMobile =
      carouselComponent && carouselComponent.hasAttribute('data-one-row-on-mobile');

    !!this.container && this.bindEvents();
  }

  /**
   * Post process any product data in need of processing.
   *
   * @param {array} products - Array of products.
   * @returns {array} Array of products.
   */
  postProcessProducts(products) {
    return products.map((product, index) => {
      if (product.signings && product.signings.length) {
        let noPositionCounter = 0;

        product.signings = product.signings.map(signing => {
          if (
            !signing.cssClasses ||
            !signing.positionClass ||
            !signing.positionClass.includes('--position') ||
            signing.cssClasses.includes('--position6') ||
            signing.cssClasses.includes('--position5')
          ) {
            noPositionCounter++;
            signing.orderClass = `order--${noPositionCounter}`;
          }

          return signing;
        });
      }

      return TileDataProcessing.postProcessProductData({
        product,
        index,
        currentPage: 0,
        pageSize: 0,
        list: TileDataProcessing.LIST_TYPES.RVP,
        isLazy: true,
        lqip: Boolean(AppSettings?.backgroundImages?.recentlyViewedBgImageEnabled),
      });
    });
  }

  /**
   * Returns the products to be rendered.
   *
   * @param {object|array|null} products - Product(s).
   * @param {string} [excludeSku] Optional product sku in order
   * to exclude product from the returned products array.
   * @returns {array} Array of products.
   */
  getProducts(products, excludeSku) {
    let result;

    if (products) {
      result = Array.isArray(products) ? products : [products];
    } else {
      result = rvpManager.getProducts(excludeSku);
    }

    return this.postProcessProducts(result);
  }

  /**
   * Renders the recently viewed products.
   *
   * @param {array} products - Array of items to render.
   */
  render(products) {
    Logger.time('render');
    Logger.debug('render');
    let slideCarousel;
    let innerContainer;
    let signingEnabled;

    if (this.container) {
      slideCarousel = this.container.querySelector(Selectors.CAROUSEL);
      innerContainer = this.container.querySelector(Selectors.CAROUSEL_CONTENTS);
    }

    products.forEach(product => {
      if (product.signings && product.signings.length) {
        let noPositionCounter = 0;

        // original order is required for setting correct classes futher down the road
        product.signings
          .reverse()
          .map(signing => {
            if (
              !signing.positionClass ||
              !signing.positionClass.includes('--position') ||
              signing.positionClass.includes('--position6') ||
              signing.positionClass.includes('--position5')
            ) {
              noPositionCounter++;
              signing.orderClass = `order--${noPositionCounter}`;
            }

            return signing;
          })
          .reverse();
      }
    });

    if (innerContainer && slideCarousel) {
      signingEnabled = slideCarousel.dataset.signingEnabled === 'true';
      this.bindRemoveEvents(this.container.jq);

      this.getHtml(products, signingEnabled)
        .then(viewsHtml => {
          if (/[\S]+/.test(viewsHtml)) {
            if (products) {
              innerContainer.insertAdjacentHTML('afterbegin', viewsHtml);
            } else {
              innerContainer.innerHTML = viewsHtml;
            }

            if (device.isMobile && !matchBreakpoint('xsmall', device.screenWidth)) {
              if (this.oneRowOnMobile) {
                SlideCarousel.attachTo(slideCarousel.jq);
              } else {
                ImageLazyLoad.observe(`${Selectors.CAROUSEL} ${Selectors.LAZY_IMAGE}`);
                new TrackingObserver(innerContainer);
              }
            } else if (slideCarousel.classList.contains(Classes.CAROUSEL_INITIALIZED)) {
              slideCarousel.jq.trigger(EventTypes.SLIDE_CAROUSEL_RENDER_REQUEST);
            } else {
              SlideCarousel.attachTo(slideCarousel.jq);
            }

            document.jq.trigger(EventTypes.RECENTLY_VIEWED_PRODUCTS_RENDER_SUCCESS);
          } else {
            document.jq.trigger(EventTypes.RECENTLY_VIEWED_PRODUCTS_NO_PRODUCTS_FOUND);
          }

          this.initialRenderPerformed = true;
          Logger.timeEnd('render');
        })
        .finally(() => {
          this.container
            .querySelector('.js-slideCarousel-slidesContainer')
            .classList.remove('is-pulse');
        })
        .catch(reason => {
          this.container.classList.add('is-hidden');
          Logger.error(reason);
        });
    } else {
      document.jq.trigger(EventTypes.RECENTLY_VIEWED_PRODUCTS_NO_RENDER);
      Logger.timeEnd('render');
    }
  }

  /**
   * Returns whether or not the stored products server sync has expired.
   *
   * @returns {boolean} Sync expired (true), or not yet (false).
   */
  didStoredProductsSyncExpire() {
    const timestamp = Storage.getItem(StorageKeys.RECENTLY_VIEWED_PRODUCTS_TIMESTAMP) || false;

    return !timestamp || timestamp < SERVER_SYNC_EXPIRY;
  }

  /**
   * Removes zero prices products from the product array.
   *
   * @param {array} products - Array of products.
   * @returns {array} Array of products.
   */
  removeZeroPricedProducts(products) {
    const filtered = [];

    products.forEach(product =>
      /[1-9]+/gim.test(product.basePrice)
        ? filtered.push(product)
        : rvpManager.removeProductFromStore(product.sku)
    );

    return filtered;
  }

  /**
   * Deprecated function to get product data for rendering.
   *
   * @param {array} products - Array of products.
   * @returns {array} Product data.
   * @deprecated
   */
  getDeprecatedProductData(products) {
    const filtered = this.removeZeroPricedProducts(products);

    return filtered.map(product => {
      product.image = product.image.replace(/https?:\/\//g, '');
      return product;
    });
  }

  /**
   * Function to get product data for rendering.
   *
   * @param {array} products - Array of products.
   * @returns {array} Product data.
   */
  getProductData(products) {
    return this.removeZeroPricedProducts(products);
  }

  /**
   * Deprecated method to get html based on an array of products.
   *
   * @param {array} products - Array of products.
   * @param {boolean} signingEnabled - If signings are enabled(true) or not(false).
   * @returns {string} Html string.
   */
  getDeprecatedProductHtml(products, signingEnabled) {
    return Promise.all(
      products.map(product => {
        return MustacheWrapper.render(template, {
          product,
          signingEnabled,
          signingBackground: product.signingBackground
            ? `background-color:${product.signingBackground};`
            : '',
          signingText: product.signingText ? `color:${product.signingText};` : '',
        });
      })
    );
  }

  /**
   * Removes any expired products.
   *
   * @param {array} products - Array of products.
   * @param {string} csrf - Csrf string.
   * @returns {promise} Remove expired products promise.
   */
  removeExpiredProducts(products, csrf) {
    if (!csrf || !this.didStoredProductsSyncExpire()) {
      return Promise.resolve(products);
    }

    return new Promise(resolve => {
      const skuList = products.map(product => product.sku);

      $.ajax({
        cache: false,
        type: 'POST',
        data: JSON.stringify({ skuList }),
        contentType: 'application/json',
        headers: { CSRFToken: csrf },
        url: SKU_LIST_URL,
        success({ skuList }) {
          if (!skuList || !skuList.length) {
            return resolve(products);
          }

          skuList.forEach(sku => rvpManager.removeProductFromStore(sku));
          products = products.filter(({ sku }) => skuList.indexOf(sku) === -1);
          resolve(products);
        },
        error: resolve.bind(this, products),
      });

      Storage.setItem(StorageKeys.RECENTLY_VIEWED_PRODUCTS_TIMESTAMP, Date.now());
    });
  }

  /**
   * Renders all products.
   *
   * @param {array} products - Array of products.
   * @param {boolean} signingEnabled - If signings are enabled(true) or not(false).
   * @returns {string} Render string.
   */
  renderProducts(signingEnabled, products) {
    products.forEach((product, index) => {
      const productData = JSON.parse(product.productData);
      productData.position = index + 1;
      product.productData = JSON.stringify(productData);
    });

    products = isV2 ? this.getProductData(products) : this.getDeprecatedProductData(products);

    return isV2
      ? MustacheWrapper.render(template, { products })
      : this.getDeprecatedProductHtml(products, signingEnabled);
  }

  /**
   * Returns html for the provided products.
   *
   * @param {array} products - Array of products.
   * @param {boolean} signingEnabled - If signings are enabled(true) or not(false).
   * @returns {promise} Html string promise.
   */
  getHtml(products, signingEnabled) {
    return getCSRFToken()
      .then(this.removeExpiredProducts.bind(this, products))
      .then(this.renderProducts.bind(this, signingEnabled))
      .catch(message => {
        Logger.error(message);
      });
  }

  /**
   * Handles on close component event.
   *
   * @param {object} event - Event object.
   */
  onComponentClose(event) {
    const { target } = event;
    const carousel = target.closest(Selectors.CAROUSEL);
    event.preventDefault();
    event.stopPropagation();

    if (target.matches('.js-closeSliderCarousel') && carousel) {
      const componentHeight = carousel.offsetHeight;

      carousel.classList.add('is-hidden');
      scrollTo(window.pageYOffset - componentHeight);
      rvpManager.removeAllProductFromStore();
    }
  }

  /**
   * Bind generic events.
   */
  bindEvents() {
    document.jq
      .on(EventTypes.RECENTLY_VIEWED_PRODUCTS_REMOVE_REQUEST, (event, product) =>
        this.onRemoveProductRequest(event, product)
      )
      .on(EventTypes.RECENTLY_VIEWED_PRODUCTS_ADD_REQUEST, (event, data) =>
        this.onAddProductRequest(event, data)
      )
      .one(EventTypes.RECENTLY_VIEWED_PRODUCTS_RENDER_REQUEST, (event, data) => {
        onWindowLoaded(() => {
          this.onRenderRequest(event, data);
        });
      })
      .one(
        EventTypes.RECENTLY_VIEWED_PRODUCTS_RENDER_SUCCESS,
        FiberRanking.renderStickerForProductTiles.bind(this, Selectors.CONTAINER)
      );

    this.container.jq.one('vclick', Selectors.CLOSE_COMPONENT, event =>
      this.onComponentClose(event)
    );
  }

  /**
   * Bind remove events.
   *
   * @param {jQuery} $recentlyViewedProducts - jQuery objects.
   */
  bindRemoveEvents($recentlyViewedProducts) {
    $recentlyViewedProducts
      .off(EventTypes.RECENTLY_VIEWED_PRODUCTS_REMOVE_CLICK_EVENT)
      .on(EventTypes.RECENTLY_VIEWED_PRODUCTS_REMOVE_CLICK_EVENT, Selectors.REMOVE, e =>
        this.onRemoveProductClick(e)
      );
  }

  /**
   * Handles on remove product request events.
   *
   * @param {object} event - Event object.
   * @param {object} product - Product object.
   */
  onRemoveProductRequest(event, product) {
    rvpManager.removeProductFromStore(product?.sku);
  }

  /**
   * Handles on add product request events.
   *
   * @param {object} event - Event object.
   * @param {object} data - Event data.
   */
  onAddProductRequest(event, data = {}) {
    const product = data.productData;
    rvpManager.addProductToStore(product);

    if (data.renderView !== false) {
      const products = this.getProducts(this.initialRenderPerformed ? product : null);
      this.render(products);
    }
  }

  /**
   * Handles on render request events.
   *
   * @param {object} event - Event object.
   * @param {object} data - Data object.
   */
  onRenderRequest(event, data = {}) {
    const products = this.getProducts(null, data.excludeSku?.trim());

    if (!products.length) {
      this.container.classList.add('is-hidden');
      return;
    }

    this.render(products);
  }

  /**
   * Handles on remove product click events.
   *
   * @param {object} event - Event object.
   */
  onRemoveProductClick(event) {
    event.preventDefault();
    event.stopPropagation();

    const $product = $(event.target).closest(Selectors.PRODUCT);
    const $recentlyViewedProducts = this.container.jq;
    const $slideCarousel = $recentlyViewedProducts.find(Selectors.CAROUSEL);
    const hasSiblings = $product.siblings().length;
    const h = $product.outerHeight();

    rvpManager.removeProductFromStore($product.data('sku'));

    gsap.to($product, {
      duration: 0.4,
      css: {
        opacity: 0,
        marginLeft: -$product.width(),
      },
      onComplete() {
        $product.remove();

        if (!hasSiblings) {
          $recentlyViewedProducts.addClass(Classes.HIDDEN);
          scrollTo(window.pageYOffset - h);
        } else {
          $slideCarousel.trigger(EventTypes.SLIDE_CAROUSEL_RENDER_REQUEST);
        }
      },
    });
  }
}

export default new RecentlyViewedProducts();
